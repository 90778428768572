import React from "react"
import { HourMin, Today, TimeOfTheDay } from "../time/time";
import { HomeStyle } from "./style";
const HomeView = () => {
    return (
        <HomeStyle>
            <div>
                <h1><HourMin /></h1>
                <h3><Today /></h3>
            </div>
            <h2><TimeOfTheDay /></h2>
            <h4>Welcome to my Web Dev Portfolio</h4>     
        </HomeStyle>
    )
}

export default HomeView

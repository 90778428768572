import React from "react";
import NavContextProvider from "./context/navContext"
import GlobalStyles from "./styles";
import Theme from "./styles/GlobalTheme";
import { Phone }from "./components"

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Theme>
        <NavContextProvider>
          <Phone />
        </NavContextProvider>
      </Theme>
    </>
  );
}

export default App;

import React, { useState, createContext } from 'react';

interface NavContextInterface {
    navState:boolean, 
    toggleNavState:Function
}

export const NavContext = createContext<NavContextInterface>({
    navState: true,
    toggleNavState: () => {}
});

const NavContextProvider = ({children}: { children:React.ReactNode }) => {
    const [navState, setNavState] = useState( true)
    const toggleNavState = () => setNavState(!navState);

    return(
        <NavContext.Provider value={ {navState, toggleNavState} }>
            {children}
        </NavContext.Provider>
    )
    
}

export default NavContextProvider
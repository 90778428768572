import styled from 'styled-components';
import { Screen } from "../../styles"

export const HomeStyle = styled(Screen)`
    background-image: url("https://s3card.s3.amazonaws.com/ast/sh3riff.me/bk1.jpg");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1{
            font-size: calc(${props => props.theme.fontSize.xLarge} * 3);
        }        
        h3{
            font-size: ${props => props.theme.fontSize.large};        
        }
    }
    h2{
        font-size: ${props => props.theme.fontSize.xLarge};
        margin: 5vh 0;
    }
    h4{
        font-size: ${props => props.theme.fontSize.medium};
        color: ${props => props.theme.color.accent};
        text-shadow: ${props => props.theme.color.neutral} -1.5px 1.5px, ${props => props.theme.color.primary} -3px 3px;
    }

`;

export const MenuStyle = styled(Screen)`
    background-image: url("https://s3card.s3.amazonaws.com/ast/sh3riff.me/bk2.jpg");
`;

export const AppUnit = styled.div`
    min-height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    grid-auto-rows: 20vw;
    gap: 4vw;
    overflow: auto;

        a{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        min-width: fit-content;
        
        p{
            font-size: calc(0.75rem + ((1vw - 3.5px) * 1.4118));
            color: ${props => props.theme.color.primary};
        }

        svg{
            height: 12vw;
            width: 12vw;
            fill: ${props => props.theme.color.primary};
        }
    }
    @media(min-width: 600px){
        grid-template-columns: repeat(6, 1fr);
    }
    @media (orientation: landscape){
        grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
        grid-auto-rows: 12vh;
        gap: 4vh;
        a{
            p{
                font-size: ${props=> props.theme.fontSize.medium}
            }
            svg{
                height: 6vh;
                width: 6vh;
            }
        }
    }
`;
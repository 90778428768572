import React, {useContext } from "react";
import { useHistory } from "react-router-dom";
import { NavContext } from "../../context/navContext";
import { NavBox } from "./style";

const NavButton = () => {
    const { toggleNavState } = useContext(NavContext);
    let history = useHistory();
    return (
        <NavBox>
                <div onClick={ () => window.location.pathname !== '/' ?  history.push("/") : toggleNavState() }>
                    <div>
                    </div>
                </div>
        </NavBox>
    )
}

export default NavButton


import styled from 'styled-components';
import { Content } from "../../styles"

export const CONTENT = styled(Content)`
    h1{
        font-size: ${props=>props.theme.fontSize.fluidH1};
        text-align: center;
        margin: ${props => props.theme.fontSize.xSmall} 0;
        &:first-child{
            margin: 0 0 ${props => props.theme.fontSize.medium} 0;
        }
        
        
    }

    img{
        height: 20vmin;
        width: 20vmin;
        border-radius: 50%;
        float: left;
        shape-outside: circle();
        margin: 0 ${props => props.theme.fontSize.xLarge} 0 0;
    }

    p{
        font-size: ${props=>props.theme.fontSize.fluidP};
        line-height: calc(${props=>props.theme.fontSize.fluidP} * 1.5);
    }

    @media (orientation: landscape){
        overflow: scroll;
        h1{
            font-size: calc(${props=>props.theme.fontSize.fluidH1} * .3);
        }
        p{
            font-size: calc(${props=>props.theme.fontSize.fluidP} * .3);
            line-height: calc(${props=>props.theme.fontSize.fluidP} * .45);
        }
    }


    ul{
        margin: 2vh 2vw;
    }
    div{
        display: flex;
        margin: 5vmax 0 2vmax;
        justify-content: space-evenly;
        a{
            width: calc(${props => props.theme.fontSize.xLarge} * 3);
            height: calc(${props => props.theme.fontSize.xLarge} * 3);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${props => props.theme.color.primary};
            border-radius: 50%;
            border: medium solid ${props => props.theme.color.accent};
            font-size: 5vw;            
            box-shadow: 0 calc(${props => props.theme.fontSize.xSmall} * .5)  ${props => props.theme.color.neutral}, 0 ${props => props.theme.fontSize.xSmall}  ${props => props.theme.color.primary};
            &:hover, &:active{
                position: relative;
                top: .5vw;
                box-shadow: 0 .5vw  ${props => props.theme.color.primary};
            }
            svg{
                width: calc(${props => props.theme.fontSize.xLarge} * 2);
                height: calc(${props => props.theme.fontSize.xLarge} * 2);
                margin: ${props => props.theme.fontSize.xSmall} 0;
                fill: ${props => props.theme.color.neutral};
            }
        }
    }
`;

export const Li = styled.li <{ margin?: number }>`
    font-size: ${props=>props.theme.fontSize.fluidP};
    line-height: calc(${props=>props.theme.fontSize.fluidP} * 1.5);
    font-weight: bold;
    margin: 0 0 0 calc(${props => props.theme.fontSize.fluidP} * ${props => props.margin});
    img{
        height: calc(${props=>props.theme.fontSize.fluidP} * .7);
        width: calc(${props=>props.theme.fontSize.fluidP} * .7);
        margin: calc(${props => props.theme.fontSize.fluidP} * 0.4) calc(${props => props.theme.fontSize.fluidP} * 0.4) 0 0;
    }
    @media (orientation: landscape){
        font-size: calc(${props=>props.theme.fontSize.fluidP} * .3);
        line-height: calc(${props=>props.theme.fontSize.fluidP} * .45);
        margin: 0 0 0 calc(${props => props.theme.fontSize.fluidP} * ${props => props.margin} *.3);
        img{
            height: calc(${props=>props.theme.fontSize.fluidP} * .7 * .3);
            width: calc(${props=>props.theme.fontSize.fluidP} * .7 * .3);
            margin: calc(${props => props.theme.fontSize.fluidP} * .4 * .3) calc(${props => props.theme.fontSize.fluidP} * .4 * .3) 0 0;
        }
    }
`;
import React from 'react'
import { NavReturn} from "../../components"
import { SafeScreen2, Content } from "../../styles"

const BankApp = () => {
    return (
        <SafeScreen2>
            <NavReturn />
            <Content>
                <h1>Work in progress...</h1>
            </Content>           
        </SafeScreen2>
    )
}

export default BankApp

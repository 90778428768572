import styled from 'styled-components';

export const PageNav = styled.div`
    position: relative;
    padding: 0 calc(${props=> props.theme.fontSize.xLarge} * 1);
    span{
        position: absolute;
        svg{
            height: 6vh;
            width: 6vh;
        }
    }
`;
import React, { useContext } from "react";
import { NavContext } from "../../context/navContext";
import {HomeView, MenuView} from "../../components"

const Homepage = () => {
    const { navState } = useContext(NavContext);

    return navState ? <HomeView /> : <MenuView />
}

export default Homepage

import styled from "styled-components";

export const PhoneBox = styled.main`
    height: 100vh;
    width: 100vw;
    border: thick solid ${props => props.theme.color.accent};
    border-radius: ${props => props.theme.fontSize.xLarge};
    position: relative;

    @media (orientation: landscape){
        max-width: 75vh;
    }
    &:fullscreen{
        background-color: ${props => props.theme.color.neutral};
    }
`;
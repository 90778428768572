import styled from 'styled-components';

export const CalculatorStyles = styled.div`
  background-color: ${props => props.theme.color.primary};
  border-radius: 25px;
  margin: 20px auto 0;
  width: 60vmin;
  min-width: 320px;
  padding: 20px;
  height: fit-content;
  max-height: 75vh;
  display: grid;
  justify-items: center;
  grid-template-rows: minmax(200px 350px) 1fr;
  grid-template-columns: 1fr;
  .display {
    margin: 0;
    width: 100%;
    @media (max-width: 500px) {
      width: 100%;
      max-height: 200px;
    }
  }
  .number-pad {
    /* grid-area: numbers; */
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    padding: 10px;
    width: 100%;
    height: 100%;
    margin: 0;
    button {
      width: 100%;
      border-radius: 20px;
      border: 3px solid white;
      font-size: 2rem;
      color: ${props => props.theme.color.neutral};
      background: ${props => props.theme.color.primary};
      cursor: pointer;
      &:hover, &:active{
          position: relative;
          top: 3.5px;
          transform: scale(.85, .85);
          background-color: ${props => props.theme.color.neutral};
          border-color: ${props => props.theme.color.red};
          color: ${props => props.theme.color.red};
      }
    }
  }
  .zero-button {
    grid-column: 1/3;
  }
`;

export const DisplayStyles = styled.div`
  display: grid;
  grid-template-rows: 70px 35px;
  grid-template-columns: 1fr;
  border: 4px solid ${props => props.theme.color.red};
  max-width: 700px;
  margin: 10px auto;
  align-items: center;
  border-radius: 20px;
  background: ${props => props.theme.color.neutral};
  @media (max-width: 500px) {
    width: 95%;
    grid-template-rows: 60px 40px;
  }
  h2,
  p {
    text-align: right;
    color: ${props => props.theme.color.primary};
    padding: 0 20px;
  }
  h2 {
    font-size: 2.5rem;
    margin: 0;
    text-align: right;
    padding: 15px 20px;
    @media (max-width: 500px) {
      font-size: 1.5rem;
      padding: 10px;
    }
  }
  h2.long-main-display {
    font-size: 1.2rem;
  }
  p {
    margin: 5px 0;
    font-size: 1.3rem;
    @media (max-width: 500px) {
      font-size: 0.8rem;
    }
  }
  p.long-stored-display {
    font-size: 0.5rem;
  }
`;

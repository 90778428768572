import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { StatusBar, NavBorder } from "../../components";
import { Homepage, Sh3riff, Calculator, BankApp, WorkInProgress } from "../../pages"
import { PhoneBox } from "./style";

const Phone = () => {
    return (
        <PhoneBox>
            <Router>
                <StatusBar />
                <Switch>
                    <Route exact path='/' component={Homepage}/>
                    <Route exact path='/sh3riff' component={Sh3riff}/>
                    <Route exact path='/bankapp' component={BankApp}/>
                    <Route exact path='/calculator' component={Calculator}/>
                    <Route exact path='/calendar' component={WorkInProgress}/>
                    <Route exact path='/downloads' component={WorkInProgress}/>
                    <Route exact path='/map' component={WorkInProgress}/>
                    <Route exact path='/profile' component={WorkInProgress}/>
                    <Route exact path='/settings' component={WorkInProgress}/>
                    <Route exact path='/timex' component={WorkInProgress}/>
                    <Route component={Homepage}/>
                </Switch>
                <NavBorder />
            </Router>         
        </PhoneBox>
    )
}

export default Phone

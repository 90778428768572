import React from 'react';
import { NumberButton, FunctionButton, ClearButton, Display, EqualButton, BackButton, NegativeButton } from './index.js';
import NumberProvider from './NumberProvider';
import { CalculatorStyles } from './style';

const Calculator = () => (
  <NumberProvider>
    <CalculatorStyles>
      <div className="display">
        <Display />
      </div>
      <div className="number-pad">
        <ClearButton />
        <BackButton />
        <NegativeButton />
        <FunctionButton buttonValue="/" />
        <NumberButton buttonValue={7} />
        <NumberButton buttonValue={8} />
        <NumberButton buttonValue={9} />
        <FunctionButton buttonValue="*" />
        <NumberButton buttonValue={4} />
        <NumberButton buttonValue={5} />
        <NumberButton buttonValue={6} />
        <FunctionButton buttonValue="-" />
        <NumberButton buttonValue={1} />
        <NumberButton buttonValue={2} />
        <NumberButton buttonValue={3} />
        <FunctionButton buttonValue="+" />
        <div className="zero-button">
          <NumberButton buttonValue={0} />
        </div>
        <NumberButton buttonValue="." />
        <EqualButton />
      </div>
    </CalculatorStyles>
  </NumberProvider>
);

export default Calculator;

import styled from 'styled-components';

export const Bar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: calc(${props => props.theme.fontSize.xLarge} * 1.5);
    background: rgba(255, 255, 255, 0.7);
    border-radius: ${props => props.theme.fontSize.medium} ${props => props.theme.fontSize.medium} 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 ${props => props.theme.fontSize.small};
`;

export const Icons = styled.div`
    svg{
        fill: ${props => props.theme.color.primary};
        height: ${props => props.theme.fontSize.large};
        width: ${props => props.theme.fontSize.large};
        margin: 0 0 0 ${props => props.theme.fontSize.xSmall};
        &:first-child{
            margin: 0;
        }
    }
`;

export const Time = styled.span`
    time{
        color: ${props => props.theme.color.primary};
        font-size: ${props => props.theme.fontSize.large};
    }
`;
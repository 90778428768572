import React from 'react';


export const SheriffSVG = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-10 -10 532 532" height="50px" width="50px">
            <path d="m402.23 168.55-136.78-89.646-137.98 88.868 273.57 179.29-137.98 88.868-136.78-89.646" stroke="#000040" stroke-width="36.993"/>
            <path d="m427.99 167.5-68.99 44.434-102.59-67.235-34.495 22.217 273.57 179.29-241.46 155.52-205.17-134.47 103.48-66.651 102.59 67.235 34.495-22.217-273.57-179.29 241.46-155.52 205.17 134.47z" stroke="#b86b77" stroke-width="18.497"/>
        </svg>
    )
}
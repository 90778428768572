import React from 'react';
import { Bar, Icons, Time } from './style';
import { FaBluetoothB, FaBatteryFull } from "react-icons/fa";
import { FiWifi } from "react-icons/fi";
import { GiNetworkBars } from "react-icons/gi";
import { HourMin } from '../time/time';


const StatusBar = () => {
    return (
        <Bar>
            <Time>
                <HourMin />
            </Time>
            <Icons>
                <FaBluetoothB />
                <GiNetworkBars />
                <FiWifi />            
                <FaBatteryFull />
            </Icons>
        </Bar>
    )
}

export default StatusBar

import React from 'react'
import { IoMdArrowRoundBack } from "react-icons/io"
import { Topic} from "../../styles"
import { PageNav} from "./style"

const NavReturn = () => {
    return (
        <PageNav>
            <span onClick={ () => window.history.back() }><IoMdArrowRoundBack/></span>
            <Topic>
                <div style={{}}>{window.location.pathname.substr(1).toUpperCase()}</div>
            </Topic>
        </PageNav>
    )
}

export default NavReturn

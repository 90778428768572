import React from 'react'
import { NavReturn, CalculatorComponent } from "../../components"
import { SafeScreen2, Content } from "../../styles"

const Calculator = () => {
    return (
        <SafeScreen2>
            <NavReturn />
            <Content>
                <CalculatorComponent />
            </Content>           
        </SafeScreen2>
    )
}

export default Calculator

import React from "react";
import { ThemeProvider } from "styled-components";
import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    color: {
      primary:string,
      accent:string,
      neutral:string,
      grey:string,
    },
    fontSize:{
        xSmall:string,
        small:string,
        medium:string,
        large:string
        xLarge:string,
        fluidH1:string,
        fluidP:string,
    }
  }
}

export const theme: DefaultTheme ={
  color:{
      primary: '#000040',
      accent: '#b86b77',
      neutral: '#fff',
      grey: '#ccc',
  },
  fontSize:{
      xSmall: ".5rem",
      small: ".75rem",
      medium: "1rem",
      large: "1.25rem",
      xLarge: "1.5rem",
      fluidH1:"calc(1.125rem + ((1vw - 3.6px) * 9.1667))",
      fluidP:"calc(0.75rem + ((1vw - 3.6px) * 4.7222))"
  }   
};

const Theme = ({ children}:{ children:React.ReactNode }) => (
  <ThemeProvider theme={ theme }>
      {children}
  </ThemeProvider>
);

export default Theme;

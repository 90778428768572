import React from "react";
import { NavReturn } from "../../components"
import { SafeScreen2 } from "../../styles"
import { CONTENT, Li } from "./style"
import { MdEmail } from "react-icons/md"
import { FaGithub, FaTwitter } from "react-icons/fa"
import icon from "../../assets/sh3riffIcon192.png"

const stackList = ["HTML", "S(CSS)", "Javascript (ES6+)" , "Typescript", "PWA", "React", "React Native", "Vue", "GraphQL", "Node JS", "Python", "MongoDB", "SQL", "Docker", "AWS Serverless", "More..."]

const listIndex = (arr:string[], index:number) =>{
    let arrayLength = arr.length
    if(index < (arrayLength/2) ) return index
    return arrayLength - index - 1
}

const sh3riff = () => {
    return (
        <SafeScreen2>
            <NavReturn />
            <CONTENT>
                <h1> Web Developer </h1>
                <img src="https://s3card.s3.amazonaws.com/ast/sh3riff.me/pix.jpg" alt="sheriff"/>
                <p > Hi, welcome to <b>sh3riff.me</b> I am Olowolagba Sheriff, a fullstack web developer with an ever expanding <i>stack.</i> I build <b>performant</b> web & native apps using modern best practices. This portfolio has links to other projects I have create overtime.</p>
                <h1> Tech Stack </h1>          
                <p> Technology in my stack include but no limited to</p>
                <ul>
                    {stackList.map((stack, index) => <Li key={stack} margin={listIndex(stackList, index)}><img src={icon} alt="sh3riff.me icon"/>{stack}</Li>)}
                </ul>
                <div>
                    <a href="mailto:contactme@sh3riff.me" > <MdEmail /></a>
                    <a href="https://github.com/sh3riff" target="_blank" rel="noopener noreferrer nofollow" > <FaGithub /> </a>
                    <a href="https://twitter.com/sh3riff_me" target="_blank" rel="noopener noreferrer nofollow" > <FaTwitter /> </a>
                </div>
            </CONTENT>           
        </SafeScreen2>
    )
}

export default sh3riff

import React, { useState } from "react"
import { NavLink } from 'react-router-dom';
import { MenuStyle, AppUnit } from "./style";
import { SafeScreen, Topic, Content } from "../../styles"
import  { SheriffSVG } from "../../assets/SVG";
import { AiOutlineBank, AiOutlineGlobal, AiOutlineCalculator, AiOutlineSetting, AiOutlineClockCircle, AiOutlineDownload } from "react-icons/ai";
import { GoCalendar } from "react-icons/go";
import { BiUserCircle } from "react-icons/bi";


const MenuView = () => {
    const appList =[
        {link: 'sh3riff', svg: <SheriffSVG />, name: 'Sh3riff.me', id: 'sh3riff.me'},
        {link: 'bankapp', svg: <AiOutlineBank />, name: 'Bank app', id: 'bankapp'},
        {link: 'calculator', svg: <AiOutlineCalculator />, name: 'Calculator', id: 'calculator'},
        {link: 'calendar', svg: <GoCalendar />, name: 'Calendar', id: 'calendar'},
        {link: 'downloads', svg: <AiOutlineDownload />, name: 'Downloads', id: 'downloads'},
        {link: 'map', svg: <AiOutlineGlobal />, name: 'Map', id: 'map'},
        {link: 'profile', svg: <BiUserCircle />, name: 'Profile', id: 'profile'},
        {link: 'settings', svg: <AiOutlineSetting />, name: 'Settings', id: 'settings'},
        {link: 'timex', svg: <AiOutlineClockCircle />, name: 'Time-X', id: 'time-x'},
    ]
    const [apps, setApps] = useState(appList);

    

    return (
        <MenuStyle>
            <SafeScreen>
                <Topic>
                    <input type="text" placeholder='Search Apps' onChange={ e => setApps(appList.filter( app => app.id.indexOf(e.target.value.toLowerCase()) !== -1 ))}/>
                </Topic>
                <Content>
                    <AppUnit>
                        {apps.map(app => <NavLink to={`/${app.link}`}  key={app.id}> {app.svg} <p>{app.name}</p> </NavLink>)}
                    </AppUnit>
                </Content>                
            </SafeScreen>
        </MenuStyle>
    )
}

export default MenuView

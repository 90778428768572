import styled, { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *{
    margin: 0;
    padding: 0;
    list-style-type: none;
    box-sizing: border-box;
    font-family: 'Gugi', cursive;
    outline: none;
    text-decoration: none;
    }
    body{
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("https://s3card.s3.amazonaws.com/ast/sh3riff.me/wood-bk.jpg");
    }
`;

export const Screen = styled.main`
    height: calc(90vh); // 10vh is navbar Height
    width: 100%;
    border-radius: calc(${props => props.theme.fontSize.medium} * 1.1) calc(${props => props.theme.fontSize.medium} * 1.1) 0 0;
    color: ${props => props.theme.color.primary};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;
export const SafeScreen = styled(Screen)`
    height: calc(90vh - ${props => props.theme.fontSize.xLarge} * 1.5); // status bar height
    border-radius: 0;
    position: relative;
    top: calc(${props => props.theme.fontSize.xLarge} * 1.5);
`;
export const SafeScreen2 = styled(SafeScreen)`
    background-color: ${props => props.theme.color.neutral};
`;

export const Topic = styled.div`
    width: 70%;
    height: 6vh;
    border: .6vh solid ${props => props.theme.color.accent};
    border-radius: 3vh;
    background-color: ${props => props.theme.color.primary};
    color:  ${props => props.theme.color.neutral};
    font-size: 3vh;
    padding: .5vh 2vh; 
    position: relative;
    left: 15%;

    div, input{
        width: 100%;
        height: 100%;        
        text-align: center;
        background-color: transparent;
        border: none;
        color:  ${props => props.theme.color.neutral};        
        font-size: 3vh;
    }
`;

export const Content = styled.div`
    position: relative;
    height: calc(100% - 6vh);
    width: 100%;    
    padding: ${props => props.theme.fontSize.xLarge};
    overflow-y: auto;
`;
import styled from 'styled-components';

export const NavBox = styled.nav`
    height: 10vh;
    width: 100%;    
    position: absolute;
    bottom: 0;
    left:0;
    border-radius: 0 0 ${props => props.theme.fontSize.medium} ${props => props.theme.fontSize.medium};
    background-color: ${props => props.theme.color.neutral};
    border-top: .5vh solid ${props => props.theme.color.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    div{
        border-radius: 50%;
        border: solid medium ${props => props.theme.color.accent};
        &:active{
            animation-name: click;
            animation-duration: .5s;
        }

        @keyframes click {
            0%   {bottom: 1.5vw;}
            50%  {bottom: 0;}
            100% {bottom: 1.5vw;}
        }
        div{
            height: 7.5vh;
            width: 7.5vh;
            margin: .25vh;
            background-color: ${props => props.theme.color.primary};
            border-radius: 50%;
            border: none;
        }
    }
`;